<template>
  <div class="box">
    <img src="@/assets/images/logo.png" class="mb-5 w-100" alt="">
    <p class="text-center text_info">Enter Your OTP</p>
    <div id="otp" class="mt-2">
      <input v-model="pin1" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin2" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin3" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin4" type="text" v-on:keyup="keyMonitor" maxlength="1" />
    </div>

    <div class="mt-4 butn">
      <button :class="{game_btn:true,activeBtn:isChanged}" :disabled="btnDisabled" id="sub_btn" @click="verify()">{{ btnDisabled ? "Loading ..." : "subscribe"}}</button>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api'
import AlertServie from '../../services/errors'
import Vue from 'vue'
export default {
  data () {
    return {
      prefixPHone: '+96475',
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      phone: '+96475',
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    valueChanged (Val) {
      this.isChanged = true
    },
    keyMonitor (event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        event.target.previousElementSibling.focus()
      } else {
        this.isChanged = true
        event.target.nextElementSibling.focus()
      }
    },
    async verify () {
      this.btnDisabled = true
      const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4
      const phoneNumber = this.$store.state.phoneNumber
      const ts = this.$store.state.ts
      const ti = this.$store.state.ti
      const response = await ApiService.verify(phoneNumber, otp, ts, ti)
      const self = this

      if (response && response.status === 200) {
        if (response.data.data !== "") {
          localStorage.setItem("gameExpertToken", response.data.access_token)
          ApiService.headers = {
            Authorization: "Bearer " + response.data.access_token,
            lang: 'en'
          }
          self.$router.push('/')
        } else {
          AlertServie.info(response.data.msg)

          if (response.status === 200) {
            this.checkUserStatusInterval = setInterval(async function () {
              const response = await ApiService.checkUserStatus(phoneNumber)
              if (response && response.status === 200) {
                if (response.data.status === 2 || response.data.status === 1) {
                  localStorage.setItem("gameExpertToken", response.data.access_token)
                  clearInterval(self.checkUserStatusInterval)
                  ApiService.headers = {
                    Authorization: "Bearer " + response.data.access_token,
                    lang: 'en'
                  }
                  self.$router.push('/')
                  Vue.$forceUpdate()
                } else {
                  if (response.data.status === 3) {
                    clearInterval(self.checkUserStatusInterval)
                  }
                  AlertServie.info(response.data.msg)
                  this.disabled = false
                  this.btnDisabled = false
                }
              } else {
                clearInterval(self.checkUserStatusInterval)
                AlertServie.info(response.data.msg)
                this.btnDisabled = false
                this.disabled = false
              }
            }, 5000)
          }
        }
      } else {
        clearInterval(self.checkUserStatusInterval)
        this.disabled = false
        this.btnDisabled = false
        AlertServie.errorMessage(response.data.msg)
      }
    }
  }
}

</script>

<style></style>
